.floatingdiv{
    justify-content: space-between;
    display: flex;
    background: white;
    box-shadow: var(--boxShadow);
    border-radius: 17px;
    align-items: center;
    padding: 0px 28px 0px 0px;
    height: 4.5rem;
    color: black;
}
.floatingdiv>img{
    transform: scale(0.4);
}
.floatingDiv > span {
    font-family: sans-serif;
    font-size: 16px;
    color: black;
  }